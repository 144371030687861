import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { notNilOrEmpty } from '../lib/Helpers'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& button': {
      width: '100%'
    }
  },
  form: {
    margin: '0 auto',
    maxWidth: '20rem',
    '& .form-input': {
      width: '80%!important'
    }
  },
  input: {
    marginBottom: '1.5rem',
    width: '100%'
  }
}))

export default ({ onCompletion }) => {
  const classes = useStyles()
  const [isLoading, setIsLoadingState] = React.useState(false)
  const [formData, setFormData] = React.useState({
    email: ''
  })
  const [blurredFields, setBlurredField] = React.useState([])
  function _handleInputChange(e) {
    e.preventDefault()
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const isValidEmail =
    formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) !=
    null
  const shouldShowEmailError = formData.email.length > 0 && !isValidEmail

  const requestPasswordReset = async email => {
    try {
      setIsLoadingState(true)
      const result = await fetch(process.env.GATSBY_RESET_PASSWORD_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          email: email
        })
      })
      if (result.status !== 200) {
        const body = await result.json()
        throw Error(body.error || 'Unknown error')
      }
      Swal.fire({
        title: 'Password reset request successful',
        text:
          'Check your inbox, the instructions to reset your password where sent to your email.',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonText: 'Send',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setIsLoadingState(false)
      onCompletion()
    } catch (error) {
      Swal.fire({
        title: 'Password reset request failure',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setIsLoadingState(false)
      onCompletion()
    }
  }

  return (
    <div className="container">
      <div className={`animated fadeIn section-content`}>
        <div className="form-container">
          <div className={classes.form}>
            <Box
              display="flex"
              p={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h5" gutterBottom>
                Reset your password
              </Typography>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  label="Email"
                  variant="outlined"
                  color="secondary"
                  name="email"
                  onChange={_handleInputChange}
                  value={formData.email}
                  size="small"
                  classes={{
                    root: classes.input
                  }}
                  {...{
                    error: shouldShowEmailError,
                    helperText: shouldShowEmailError
                      ? `Please enter a valid email address`
                      : ''
                  }}
                />
              </FormControl>
              <Box
                display="flex"
                p={1}
                flexDirection="row"
                alignItems="center"
                justifyItems="space-between"
                className={classes.actionsContainer}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={onCompletion}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  onClick={() => requestPasswordReset(formData.email)}
                  disabled={!(notNilOrEmpty(formData.email) && isValidEmail)}
                >
                  {isLoading ? <CircularProgress /> : 'Submit'}
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}
